<template>
  <img class="card-img lazy" :alt="alt" />
</template>

<script>
export default {
  name: "ImageLazy",

  props: {
    src: {
      type: String,
      required: false,
      default: null,
    },
    err: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      img: null,
      scrollTop: null,
    };
  },

  watch: {
    src: function (n, o) {
      this.img.src = n;
    },
  },

  mounted() {
    const self = this;

    this.img = this.$el;

    this.scrollTop = window.innerHeight + window.pageYOffset;

    this.$nextTick(function () {
      self.refreshImg(self.src);
    });
  },

  methods: {
    refreshImg(src) {
      let self = this;

      if (
        this.img.classList.contains("lazy") &&
        this.img.offsetTop < this.scrollTop
      ) {
        setTimeout(function () {
          self.img.src = src;

          self.img.classList.remove("lazy");

          self.img.onload = function () {
            let b = this;

            setTimeout(function () {
              b.classList.add("image-ready");

              setTimeout(function () {
                b.parentNode.style.background = "";
              }, 300);
            }, 0);
          };

          self.img.onerror = function () {
            let b = this;

            setTimeout(function () {
              b.src = self.err;

              b.classList.add("image-ready");

              setTimeout(function () {
                b.parentNode.style.background = "";
              }, 300);
            }, 0);
          };
        }, 100);
      }
    },
  },
};
</script>
